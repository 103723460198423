import React from 'react'
import Linked from '../general/Linked';
import LeftArrowGreen from '../../images/left-arrow-green.svg'

export default function PageNav({
    next,
    prev
}) {
    return (
        <nav className="text-knorr w-full flex justify-between py-8 max-w-screen-xl mx-auto px-4">
            { prev && <Linked linkTo={prev.linkTo} className="max-w-2/5 mr-auto flex">
                <img className="float-left w-4 h-4 mt-1 mr-2" src={LeftArrowGreen} alt="Right arrow" />
                <p ><span className="font-bold block lg:inline-block">PREVIOUS: </span> {prev.text}</p> 
            </Linked>}
            { next && <Linked linkTo={next.linkTo} className="max-w-2/5 ml-auto flex text-right">
                <p ><span className="font-bold block lg:inline-block">NEXT: </span> {next.text}</p> 
                <img className=" w-4 h-4 mt-1 ml-2 transform rotate-180" src={LeftArrowGreen} alt="Right arrow" />
            </Linked>
            }
        </nav>
    )
}
